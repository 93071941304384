@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
button,
input {
  font-family: "Poppins", sans-serif;
}
a {
  text-decoration: none;

}

.animation {
  transition: all 0.3s ease-in-out;
}

.sidebarshadow {
  box-shadow: 0 4px 14px rgba(38, 70, 83, 0.08);
}

.subscriptionboxshadow {
  box-shadow: 0 0 5px lightgrey;
}

ul li {
  list-style: none !important;
}

::-webkit-details-marker {
  display: none;
}

li::marker {
  color: #fff;
  z-index: -5;
  display: none;
}


.css-13cymwt-control  {
  min-height:  43px !important;
}

.dashboardbox {
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}


.shadowdefault {
  box-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
}


@media (max-width: 768px) {
  .dashboardbox {
    width: 100%;
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .dashboardbox {
    width: 100%;
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .dashboardbox {
    width: 100%;
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

/* Animation */

@keyframes delayAnimation {
  0% {
    opacity: 0; /* Start with opacity 0 */
  }
  50% {
    opacity: 0; /* Stay invisible for the first half of the animation */
  }
  100% {
    opacity: 1; /* Fade in at the end of the animation */
  }
}

.numinput input {
  border: 1px solid #f1eeee;
  outline: none;
  padding: 10px 10px;
  border-radius: 5px;
  margin-top: 6px;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.table {
  width: 100%;
  margin: auto;
  border-collapse: collapse;
}

.table tbody tr:nth-of-type(odd) {
  background-color: white;
  box-shadow: 0 4px 14px rgba(38, 70, 83, 0.08);
}

.table td,
.table th {
  border: 0.1rem solid #e4e4e4;
  padding: 10px;
  width: auto;
}

.tableMode {
  overflow: scroll;
}

::-webkit-scrollbar {
  display: none;
}




/* Subscription cards */
.subscriptioncards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
}

.pricingBoxes {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
}


@media (max-width: 768px) {
  .subscriptioncards {
    width: 100%;
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .pricingBoxes {
    width: 100%;
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .subscriptioncards {
    width: 100%;
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .pricingBoxes {
    width: 100%;
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (min-width: 910px) and (max-width: 1200px) {
  .subscriptioncards {
    width: 100%;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .pricingBoxes {
    width: 100%;
    grid-template-columns: repeat(2, 1fr) !important;
  }
}



@media print {
  body {
    margin: 0;
    padding: 0;
    size: 10cm 5cm;
    text-align: center !important; 

  }

  /* Add other print-specific styles here */
  /* For example, to add a border */
  body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 10cm;
    height: 10cm;
    z-index: -1;
    text-align: center !important; 

  }
}

.orderPrint {
  text-align: center !important;
  width: 10cm !important;
  height: 10cm  !important;
}

.orderBarCode {
  width: 4cm !important;
  height: 2cm  !important;

}
label {
  font-size: 14px !important;
    font-weight: 200 !important;
    text-indent: 3px !important;
}

.heropage {
  width: 100%;
  height: 100vh;
  background-image: url(../assets//images//bg.webp);
  background-size: cover;
  background-position: center;
}